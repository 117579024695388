import MenuLayout from "components/organisms/MenuLayout/MenuLayout";
import AccountSettingsPage from "components/pages/AccountSettingsPage/AccountSettingsPage";
import AdminPage from "components/pages/AdminPage/AdminPage";
import ArticlesAdminPage from "components/pages/ArticlesAdminPage/ArticlesAdminPage";
import ArticlesEditPage from "components/pages/ArticlesEditPage/ArticlesEditPage";
import ArticlesNewPage from "components/pages/ArticlesNewPage/ArticlesNewPage";
import ArticlesPage from "components/pages/ArticlesPage/ArticlesPage";
import CancersEditPage from "components/pages/CancerEditPage/CancerEditPage";
import CancerNewPage from "components/pages/CancerNewPage/CancerNewPage";
import CancersPage from "components/pages/CancersPage/CancersPage";
import DashboardPage from "components/pages/DashboardPage/DashboardPage";
import EventsPage from "components/pages/EventsPage/EventsPage";
import { FeedPage } from "components/pages/FeedPage/FeedPage";
import HomePage from "components/pages/HomePage/HomePage";
import MediaPage from "components/pages/MediaPage/MediaPage";
import PollEditPage from "components/pages/PollEditPage/PollEditPage";
import PollNewPage from "components/pages/PollNewPage/PollNewPage";
import PollsPage from "components/pages/PollsPage/PollsPage";
import ProfilePage from "components/pages/ProfilePage/ProfilePage";
import TranslationsPage from "components/pages/TranslationsPage/TranslationsPage";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { LoyaltyProgramServiceCompanyContent } from "./components/organisms/LoyaltyProgramServiceCompanyContent/LoyaltyProgramServiceCompanyContent";
import { LoyaltyProgramServiceContent } from "./components/organisms/LoyaltyProgramServiceContent/LoyaltyProgramServiceContent";
import CategoriesPage from "./components/pages/CategoriesPage/CategoriesPage";
import LoyaltyProgramSponsorPage from "./components/pages/LoyaltyProgramCompanyPage/LoyaltyProgramSponsorPage";
import LoyaltyProgramPage from "./components/pages/LoyaltyProgramPage/LoyaltyProgramPage";
import LoyaltyProgramServicePage from "./components/pages/LoyaltyProgramServicePage/LoyaltyProgramServicePage";
import PageEditPage from "./components/pages/PageEditPage/PageEditPage";
import PageNewPage from "./components/pages/PageNewPage/PageNewPage";
import PagesPage from "./components/pages/PagesPage/PagesPage";
import SystemSettingsPage from "components/pages/SystemSettingsPage/SystemSettingsPage";
import ChangesPage from "./components/pages/ChangesPage/ChangesPage";
import ChangesCompanyPage from "./components/pages/ChangesCompanyPage/ChangesCompanyPage";
import DoctorPage from "components/pages/DoctorPage/DoctorPage";
import ChangesCompanyDepartmentPage from "./components/pages/ChangesCompanyDepartmentPage/ChangesCompanyDepartmentPage";
import ChangesLoyaltyProgramServicePage from "./components/pages/ChangesLoyaltyProgramServicePage/ChangesLoyaltyProgramServicePage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store/index";
import { useGetMeQuery } from "./redux/services/meApi";
import { Suspense, useEffect, useState } from "react";
import {
  UserRole,
  setLogged,
  setUser,
  setUserRoles,
} from "./redux/Slice/user-slice";
import { useGetSystemSettingsQuery } from "redux/services/systemSettingsApi";
import {
  SystemSetting,
  setSystemSettings,
} from "redux/Slice/system-settings-slice";
import { AUTH_DATA } from "./Helpers/Roles/authData";
import TranslationsLanguagePage from "components/pages/TranslationsLanguagePage/TranslationsLanguagePage";
import VerificationPage from "./components/pages/VerificationPage/VerificationPage";
import { useGetUserRolesQuery } from "redux/services/userRolesApi";
import { MessagesPage } from "./components/pages/MessagesPage/MessagesPage";
import { UsersPage } from "./components/pages/UsersPage/UsersPage";
import { AppMeta } from "./components/atoms/AppMeta/AppMeta";
import HelpPage from "components/pages/HelpPage/HelpPage";
import { AgreementPage } from "./components/pages/AgreementPage/AgreementPage";
import { ChangesDoctorPage } from "./components/pages/ChangesDoctorPage/ChangesDoctorPage";
import { EventPage } from "components/pages/EventPage/EventTemplate";
import { setUserToken } from "redux/Slice/token-slice";
import QuickHelpButton from "components/atoms/QuickHelp/QuickHelpButton";
import NoAccessPage from "./components/pages/NoAccessPage/NoAccessPage";
import FeedCategoryPage from "./components/pages/FeedCategoryPage/FeedCategoryPage";
import { isAuthPermission } from "./Helpers/Roles/isAuthPermission";
import { useGetSFContactQuery } from "redux/services/salesforceApi";
import CardCodesPage from "./components/pages/CardCodesPage/CardCodesPage"
import LoadingScreen from "components/atoms/LoadingScreen/LoadingScreen";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { setShowMessages } from "redux/Slice/messages-slice";
import CompaniesAdminPage from "components/pages/CompaniesAdminPage/CompaniesAdminPage";
import CompanyEditPage from "components/pages/CompanyEditPage/CompanyEditPage";
import ServiceEditPage from "components/pages/ServiceEditPage/ServiceEditPage";
import { logoutHandler } from "Helpers/logoutHandler";
import {fetchProvincesStart, fetchProvincesFailure, fetchProvincesSuccess } from "redux/Slice/province-slice";
import { useGetProvincesQuery } from "redux/services/provincesApi";




function App() {
  const navigate = useNavigate();
  const { authUser, isLogged } = useSelector((state: RootState) => state.user);
  const currentToken = useSelector((state: RootState) => state.token.userToken);
  const userRolesArray = authUser?.roles?.map(
    (userRole: UserRole) => userRole?.role?.role
  );
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
  const { data: userMe, isLoading } = useGetMeQuery({});
  if(!isLoading && userMe){

    console.log(userMe.data.settings.Status_u_ytkownika__c);
  }
  
  const { data: systemSettings } = useGetSystemSettingsQuery({});
  const { data: userRoles } = useGetUserRolesQuery({ filterBy: "display" });
  const { data: sfData } = useGetSFContactQuery({});
  const { data: provinceData, isLoading: isProvincesLoading, error: provincesError } = useGetProvincesQuery({})
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isProvincesLoading) {
      dispatch(fetchProvincesStart());
    } else if (provinceData) {
      dispatch(fetchProvincesSuccess(provinceData.data));
    } else if (provincesError) {
      dispatch(fetchProvincesFailure(provincesError.toString()));
    }
  }, [provinceData, isProvincesLoading, provincesError, dispatch]);
  useEffect(() => {
    fetch("https://static.dev.api.alivia.org.pl/system/settings.json?limit=0")
      .then((response) => response.json())
      .then((data) => {
        data.forEach((setting: SystemSettingsData) => {
          if (setting.type === "general" && setting.status === 1) {
            const scriptElement = document.createElement("script");
            scriptElement.textContent = setting.value;

            if (
              setting.context === "general_scripts_head" &&
              !document.head.querySelector(
                'script[context="general_scripts_head"]'
              )
            ) {
              document.head.appendChild(scriptElement);
              scriptElement.setAttribute("context", "general_scripts_head");
            } else if (
              setting.context === "general_scripts_body" &&
              !document.body.querySelector(
                'script[context="general_scripts_body"]'
              )
            ) {
              document.body.appendChild(scriptElement);
              scriptElement.setAttribute("context", "general_scripts_body");
            }
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching the settings:", error);
      });
  }, []);


  useEffect(() => {
    if (!isLogged) {
      return;
    }
  
    let logoutTimer: NodeJS.Timeout;
    const logoutTime = 30 * 60 * 1000;
  
    const resetTimer = () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      logoutTimer = setTimeout(() => {
        logoutHandler();
      }, logoutTime);
    };
  
    const activityEvents = ["mousemove", "keydown", "click", "scroll"];
  
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );
  
    resetTimer();
  
    // Listener na eventy wylogowania z innych kart
    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === 'logoutEvent') {
        window.location.replace(`${window.location.protocol}//${window.location.host}/`);
      }
    };
  
    window.addEventListener('storage', onStorageEvent);
  
    return () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
      window.removeEventListener('storage', onStorageEvent);
    };
  }, [isLogged]);

  useEffect(() => {
    (async () => {
      if (isLoading) {
        setIsLoadingScreen(true);
      } else {
        setIsLoadingScreen(false);

        if (userMe !== undefined && userMe.data.email) {
          dispatch(
            setUser({
              id: userMe?.data?.id,
              name: userMe?.data?.name,
              authId: userMe?.data?.authId,
              email:
                userMe?.data?.localSettings?.email ??
                userMe?.data?.settings?.Email,
              roles: userMe?.data?.roles,
              createdAt: userMe?.data?.createdAt,
              deleted: userMe?.data?.deleted,
              status: userMe?.data?.status,
              updateAt: userMe?.data?.updateAt,
              avatar: userMe?.data?.avatar,
              impersonated: userMe?.data?.impersonated,
              firstName:
                userMe?.data?.settings?.FirstName ??
                userMe?.data?.localSettings?.firstName,
              lastName:
                userMe?.data?.settings?.LastName ??
                userMe?.data?.localSettings?.lastName,
              settings: {
                ...userMe?.data?.settings,
              },
              localSettings: {
                ...userMe?.data?.localSettings,
              },
            })
          );
          dispatch(setLogged(true));
          const userToken = userMe?.meta?._currentDevUserToken;
          dispatch(setShowMessages(false));
          if (userToken && userToken !== currentToken) {
            dispatch(setUserToken(userToken));
          }

          userRoles && dispatch(setUserRoles(userRoles?.userRoles));
          systemSettings && dispatch(setSystemSettings(systemSettings?.data));
          if (location.pathname === "/") {
            navigate(`/${NAV_PATH.DASHBOARD}`);
          }
        } else {
          dispatch(
            setUser({
              id: 0,
              name: "",
              email: "",
              authId: "",
              roles: [],
              createdAt: "",
              deleted: 0,
              status: 0,
              updateAt: "",
              avatar: null,
              firstName: "",
              lastName: "",
              impersonated: false,
              localSettings: {
                firstName: "",
                lastName: "",
                displayName: "",
                email: "",
                mobilePhone: "",
                birthdate: "",
                mailingStreet: "",
                mailingCity: "",
                mailingProvince: "",
                mailingProvinceId: 0,
                mailingPostalCode: "",
                mailingCountry: "",
                sex__c: "",
                Indetyfikator_kontaktu__c: "",
                Status_u_ytkownika__c: "",
                Status_choroby__c: "",
                user_Id: 0,
                facility_Id: 0,
                cancer_Id: 0,
                patient_poll_Id: null,
                doctor_poll_Id: null,
                partner_poll_Id: null,
                guardian_poll_Id: null,
                cancer_slug: "",
                agree_profiling: false,
                agree_rodo: false,
                agree_service_alivia: false,
                agree_medical_info: false,
                agree_contact_email: false,
                agree_contact_phone: false,
                Grupa_wiekowa__c: "",
                Osoba_chora__c: false,
                Lekarz__c: false,
                Partner__c: false,
                Opiekun__c: false,
                Wskazanie_na_opiekuna__c: [],
                Wskazanie_podopiecznego__c: [],
                Skarbonka_ID__c: "",
                Cel_zbiorki__c: "",
                OZ_link__c: "",
                Tytul_zbiorki__c: "",
                Dost_pne_rodki__c: "",
                Sum_of_Payout__c: "",
                Cel_zbiorki_ow__c: "",
                OZ_link_ow__c: "",
                Tytul_zbiorki_ow_cc: "",
                dost_pne_rodki_ostatnio_wspartej__c: "",
                wszystkie_wyp_aty_ostatnio_wspartej__c: "",
                saved_postsId: [],
                description: "",
                widgetsSettings: [],
                saved_articlesId: [],
              },
              settings: {
                attributes: {
                  type: "",
                  url: "",
                },
                Id: "",
                IsDeleted: false,
                MasterRecordId: null,
                AccountId: "",
                LastName: "",
                FirstName: "",
                Salutation: null,
                Name: "",
                RecordTypeId: "",
                OtherStreet: null,
                OtherCity: null,
                OtherState: null,
                OtherPostalCode: null,
                OtherCountry: null,
                OtherLatitude: null,
                OtherLongitude: null,
                OtherGeocodeAccuracy: null,
                OtherAddress: null,
                MailingStreet: null,
                MailingCity: null,
                MailingState: null,
                MailingPostalCode: null,
                MailingCountry: null,
                MailingLatitude: null,
                MailingLongitude: null,
                MailingGeocodeAccuracy: null,
                MailingAddress: null,
                Phone: null,
                Fax: null,
                MobilePhone: null,
                HomePhone: null,
                OtherPhone: null,
                AssistantPhone: null,
                ReportsToId: null,
                Email: "",
                Title: null,
                Department: null,
                AssistantName: null,
                LeadSource: null,
                Birthdate: null,
                Description: null,
                CurrencyIsoCode: "",
                OwnerId: "",
                HasOptedOutOfEmail: false,
                HasOptedOutOfFax: false,
                DoNotCall: false,
                CreatedDate: "",
                CreatedById: "",
                LastModifiedDate: "",
                LastModifiedById: "",
                SystemModstamp: "",
                LastActivityDate: null,
                LastCURequestDate: null,
                LastCUUpdateDate: null,
                LastViewedDate: "",
                LastReferencedDate: "",
                EmailBouncedReason: null,
                EmailBouncedDate: null,
                IsEmailBounced: false,
                PhotoUrl: "",
                Jigsaw: null,
                JigsawContactId: null,
                Formularze__c: 0,
                Alior_Data__c: null,
                Darczyncy__c: 0,
                Zrodlo_PZ_kontakt__c: null,
                Gender__c: null,
                GR_imi_i_nazwisko__c: "",
                Darowizny_wszystkie_data_ostatniej__c: null,
                sendgrid4sf__Email_Verification_Date__c: null,
                sendgrid4sf__Email_Verification_Status__c: null,
                sendgrid4sf__Invalid_SendGrid_Contact__c: false,
                sendgrid4sf__MassLastStayinTouchRequestDate__c: null,
                sendgrid4sf__MassLastStayinTouchSaveDate__c: null,
                sendgrid4sf__MassMailer_Email_Status_Count__c: 1,
                Program_powitalny_kontakt__c: null,
                Wielko_miasta__c: null,
                Czas_od_p_atno_ci_jednorazowej__c: null,
                Data_edycji_rekordu__c: "",
                Kategoria_deduplikacji__c: "",
                Status_u_ytkownika__c: "",
                Zakonczono_identyfikacj_ODO__c: false,
                Status_PZ__c: null,
                sendgrid4sf__Email_Verification_substatus__c: null,
                Skieruj_do_TM__c: false,
                Data_wys_ania_pakietu_powitalnego__c: null,
                Nale_y_wys_a_pakiet_powiatalny__c: "",
                Pakiet_retencyjny_wysy_ka__c: null,
                Pakiet_retencyjny_data_wys_ania__c: "",
                Data_i_czas_powstania_kontaktu__c: "",
                Wiek__c: null,
                Grupa_wiekowa__c: null,
                Bank__c: null,
                Polecenie_zap_aty_rednia_kwota__c: null,
                Darowizny_jednorazowe_rednia__c: null,
                Status_kontaktu__c: "",
                Kwota_przekazana__c: 0,
                Program_Middle_Donor__c: false,
                Wszystkie_darowizny__c: null,
                X1_podatku_rednia_kwota__c: null,
                Otrzymane_darowizny_rednia_kwota__c: null,
                Wszystkie_wyp_aty_rednia_kwota__c: null,
                Darczy_ca_cykliczny__c: false,
                Program_darowizn_jednorazowych__c: "",
                Cel_ostatniej_darowizny__c: null,
                Update__c: false,
                Skarbonka__c: 0,
                Skarbonka_suma_p_atno_ci__c: 0,
                Skarbonka_rednia_kwota__c: null,
                Skarbonka_data_ostatniej_p_atno_ci__c: null,
                Darowizny_jednorazowe__c: null,
                X1_podatku_data_ostatniej__c: null,
                Polecenie_zap_aty_data_ostatniej__c: null,
                Wszystkie_wyp_aty_data_ostatniej__c: null,
                Otrzymane_darowizny_data_ostatniej__c: null,
                Darowizny_cykliczne_suma__c: 0,
                Darowizny_cykliczne_ilo_p_atno_ci__c: 0,
                Darowizny_cykliczne_data_ostatniej__c: null,
                Darowizny_cykliczne_rednia_kwota__c: null,
                Techniczne_02__c: null,
                Powiadomienie_o_braku_srodk_w__c: false,
                Suma_darowizn__c: 0,
                Wojew_dztwo_firmy__c: null,
                Wo_acz_formalny__c: "",
                Podopieczny__c: false,
                Liczba_porz_d__c: null,
                ID18__c: "",
                Upgrade_data_rozmowy__c: null,
                Maksymalna_data_PZ__c: null,
                Upgrade_rozmowa__c: "",
                Tekstowa_nazwa_firmy__c: null,
                Tytu_celownik__c: null,
                Tytu_biernik__c: null,
                Tytu_wo_acz__c: null,
                Wo_acz_formalny_bez_imienia__c: "",
                Cloudrop__DateTime__c: null,
                Cloudrop__Invalid__c: false,
                Cloudrop__Languages__c: null,
                Cloudrop__Level__c: null,
                Wskazuje_na_Skarbonk__c: false,
                Choroba__c: "",
                Data_rozpocz_cia_subskrypcji__c: null,
                Ilo_podpisanych_petycji__c: 0,
                Wszystkie_transakcje_PZ__c: 0,
                daneztransakcji__c: null,
                Data_podpisania_umowy__c: null,
                Data_wys_ania_formularza__c: null,
                Ilo_podstaw_prawnych__c: 0,
                Wskazuje_na_Skarbonkogolnie__c: false,
                Mailing_pole_steruj_ce__c: false,
                Konto_uzytkownika__c: false,
                Regularni__c: false,
                PESEL__c: null,
                Program_beneficjencki__c: false,
                Last_DD_Cancelation_Date__c: null,
                CTP_rozpocz_cie_okresu__c: null,
                CTP_zako_czenie_okresu__c: null,
                CTP_ilo_p_atno_ci__c: 0,
                Last_DD_Cancelation_Code__c: null,
                Ostateczny_kod_odrzucenia__c: null,
                Ostateczna_data_odrzucenia__c: null,
                Status_darczy_cy__c: "",
                P_atno_ci_regularne_data_ostatniej__c: null,
                GR_usuni_to__c: false,
                Przeprowad_deduplikacj__c: false,
                Priorytet_deduplikacji__c: "",
                Duplikat__c: false,
                Przeprowad_kontrol_duplikat_w__c: false,
                Docelowy_kontakt__c: null,
                Inny_priorytet_deduplikacji__c: "",
                Identyfikator_kontaktu__c: null,
                Docelowy__c: false,
                Typ_firmy__c: null,
                Media__c: false,
                Firmy__c: false,
                Zbiorki__c: false,
                Informacja_o_chorobie__c: null,
                user_Id__c: null,
                Osoba_chora__c: false,
                Lekarz__c: false,
                Partner_programu_beneficjenckiego__c: false,
                Opiekun__c: false,
                Wskazanie_na_opiekuna__c: null,
                Wskazanie_podopiecznego__c: null,
                Id_zbi_rki__c: null,
                Cel_zbiorki__c: null,
                Link_do_zbi_rki__c: null,
                Tytu_zbi_rki__c: null,
                Id_zbi_rki_ostatnio_wspartej__c: null,
                Cel_zbiorki_ostatnio_wspartej__c: null,
                Dost_pne_rodki__c: null,
                Link_do_zbiorki_ostatnio_wspartej__c: null,
                Tytu_zbiorki_ostatnio_wspartej__c: null,
                Dost_pne_rodki_ostatnio_wspartej__c: null,
                Wszystkie_wyp_aty_ostatnio_wspartej__c: null,
                Zgoda_na_profilowanie__c: false,
                Profilowanie__c: "",
                Etap_leczenia__c: null,
                agree_rodo__c: false,
                agree_service_alivia__c: false,
                agree_medical_info__c: false,
                agree_contact_email__c: false,
                agree_contact_phone__c: false,
                Do_usuni_cia__c: false,
                Ostatnie_logowanie_HUB__c: null,
                ID_nowotworu_HUB__c: null,
                pollID__c: 0,
                Partner__c: null,
              },
            })
          );
          dispatch(setLogged(false));
        }
      }
    })();
    // eslint-disable-next-line
  }, [userMe, isLoading]);


  const isDoctor: boolean = AUTH_DATA.DOCTOR.some(
    (doctorRole: string) => userRolesArray?.includes(doctorRole),
  );

  const isSponsor: boolean = AUTH_DATA.SPONSOR.some((sponsorRole: string) =>
    userRolesArray?.includes(sponsorRole)
  );

  const isAdmin: boolean = AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
    userRolesArray?.includes(adminRole)
  );

  // zamiana Messengera na inny system
  // https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76
  // const isCallCenter: boolean = AUTH_DATA.CALL_CENTER.some(
  //   (callCenter: string) => userRolesArray?.includes(callCenter),
  // );

  const isNoRole: boolean = Boolean(userRolesArray?.length === 0);

  const hasNoSolvedPolls: boolean = userRolesArray
    ?.map((role: string) => {
      const index: string = role + "_poll_Id";
      const setting: SystemSetting = systemSettings?.data?.find(
        (systemSetting: SystemSetting) => systemSetting?.context === role
      );
      const isPollAvailable = systemSettings && Boolean(setting?.value !== "");
      if (authUser.settings && isPollAvailable && index in authUser.settings) {
        return Boolean(
          authUser.settings[
            index as keyof typeof authUser.settings
          ]?.toString() === null ||
            authUser.settings[
              index as keyof typeof authUser.settings
            ]?.toString() !== setting?.value?.toString()
        );
      } else {
        return false;
      }
    })
    .includes(true);

  if (isLoadingScreen) {
    return <LoadingScreen />;
  }

  if (isLogged) {
    if (
      authUser?.status === 0 ||
      authUser?.status === 100 ||
      authUser?.status === 90 ||
      sfData?.data?.Dead__c ||
      sfData?.data?.Do_usuni_cia__c ||
      authUser?.status === 200
    ) {
      return (
        <>
          <Suspense>
            <AppMeta />
            <Routes>
              <Route index element={<DashboardPage />} />
              <Route element={<MenuLayout />}>
                {[NAV_PATH.DASHBOARD, NAV_PATH.DASHBOARD_EN].map(
                  (dashboardPath) => (
                    <Route
                      path={`/${dashboardPath}`}
                      element={<DashboardPage />}
                      key={dashboardPath}
                    />
                  )
                )}
                <Route
                  path={`/${NAV_PATH.PROFILE}/:authID`}
                  element={<ProfilePage />}
                />
                <Route
                  path={`/${NAV_PATH.PROFILE}`}
                  element={<ProfilePage />}
                />
                <Route
                  path={`/${NAV_PATH.SETTINGS}`}
                  element={<AccountSettingsPage />}
                />
                <Route path={`/${NAV_PATH.HELP}`} element={<HelpPage />} />
              </Route>
              {[NAV_PATH.VERIFICATION, NAV_PATH.VERIFICATION_EN].map(
                (verificationPath) => (
                  <Route
                    path={`/${verificationPath}`}
                    element={<VerificationPage />}
                    key={verificationPath}
                  />
                )
              )}
              <Route path="*" element={<DashboardPage />} />
            </Routes>
          </Suspense>
        </>
      );
    }

    if (hasNoSolvedPolls || isNoRole) {
      return (
        <>
          <Suspense>
            <AppMeta />
            <Routes>
              <Route element={<MenuLayout />}>
                <Route
                  index
                  element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                />
                {[NAV_PATH.DASHBOARD, NAV_PATH.DASHBOARD_EN].map(
                  (dashboardPath) => (
                    <Route
                      path={`/${dashboardPath}`}
                      element={<DashboardPage />}
                      key={dashboardPath}
                    />
                  )
                )}
                <Route
                  path={`/${NAV_PATH.PROFILE}/:authID`}
                  element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                />
                <Route
                  path={`/${NAV_PATH.PROFILE}`}
                  element={<ProfilePage />}
                />
                <Route
                  path={`/${NAV_PATH.SETTINGS}`}
                  element={<AccountSettingsPage />}
                />
                <Route
                  path={`/${NAV_PATH.ARTICLES}`}
                  element={<ArticlesPage />}
                />
                {[NAV_PATH.FEED, NAV_PATH.FEED_EN].map((feedPath) => (
                  <Route
                    path={`/${feedPath}`}
                    element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    key={feedPath}
                  >
                    <Route
                      path=":feedId"
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                    {feedPath === NAV_PATH.FEED && (
                      <Route
                        path={`:feedId/${NAV_PATH.COMMENT}/:commentId`}
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    )}
                    {feedPath === NAV_PATH.FEED_EN && (
                      <Route
                        path={`:feedId/${NAV_PATH.COMMENT_EN}/:commentId`}
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    )}
                  </Route>
                ))}

                <Route path={`/${NAV_PATH.EVENTS}`} element={<EventsPage />} />
                <Route
                  path={`${NAV_PATH.EVENTS}/:id`}
                  element={<EventPage />}
                />
                {isDoctor && (
                  <Route path={`${NAV_PATH.DOCTOR}`}>
                    <Route
                      path=":me"
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                  </Route>
                )}
                {[NAV_PATH.LOYALTY_PROGRAM, NAV_PATH.LOYALTY_PROGRAM_EN].map(
                  (loyaltyProgramPath) => (
                    <Route
                      path={`${loyaltyProgramPath}`}
                      key={loyaltyProgramPath}
                    >
                      <Route index element={<LoyaltyProgramPage />} />
                      {loyaltyProgramPath === NAV_PATH.LOYALTY_PROGRAM && (
                        <>
                          <Route
                            path={`${NAV_PATH.SERVICE}`}
                            element={<LoyaltyProgramServicePage />}
                          />
                          <Route
                            path={`${NAV_PATH.SERVICE}/:id`}
                            element={<LoyaltyProgramServiceContent />}
                          />
                          <Route
                            path={`${NAV_PATH.COMPANY}/:id`}
                            element={<LoyaltyProgramServiceCompanyContent />}
                          />
                        </>
                      )}
                      {loyaltyProgramPath === NAV_PATH.LOYALTY_PROGRAM_EN && (
                        <>
                          <Route
                            path={`${NAV_PATH.SERVICE_EN}`}
                            element={<LoyaltyProgramServicePage />}
                          />
                          <Route
                            path={`${NAV_PATH.SERVICE_EN}/:id`}
                            element={<LoyaltyProgramServiceContent />}
                          />
                          <Route
                            path={`${NAV_PATH.COMPANY_EN}/:id`}
                            element={<LoyaltyProgramServiceCompanyContent />}
                          />
                        </>
                      )}
                    </Route>
                  )
                )}
                {isSponsor && (
                  <Route
                    path={`${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}`}
                  >
                    <Route
                      path={`${NAV_PATH.COMPANY}/:id`}
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                  </Route>
                )}
                <Route
                  path={`${NAV_PATH.HELP}`}
                  element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                />
                {/*zamiana Messengera na inny system*/}
                {/*https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76*/}
                {/*{isCallCenter && (*/}
                {/*  <Route path={`${NAV_PATH.ADMIN}`}>*/}
                {/*    <Route*/}
                {/*      path={`${NAV_PATH.MESSENGER}`}*/}
                {/*      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}*/}
                {/*    />*/}
                {/*  </Route>*/}
                {/*)}*/}
                {isAdmin && (
                  <Route path={`${NAV_PATH.ADMIN}`}>
                    <Route
                      index
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                    {/*zamiana Messengera na inny system*/}
                    {/*https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76*/}
                    {/*<Route*/}
                    {/*  path={`${NAV_PATH.*/}
                    {/*    MESSENGER}`}*/}
                    {/*  element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}*/}
                    {/*/>*/}
                    <Route path={`${NAV_PATH.ARTICLES}`}>
                      <Route
                        index
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path="new"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path=":id/edit"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    </Route>
                    <Route
                      path={`${NAV_PATH.CATEGORIES}`}
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                    <Route
                      path={`${NAV_PATH.MEDIA}`}
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                    <Route path={`${NAV_PATH.CANCERS}`}>
                      <Route
                        index
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path="new"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path=":id/edit"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    </Route>
                    {[NAV_PATH.FEED, NAV_PATH.FEED_EN].map((feedPath) => (
                      <Route
                        path={`${feedPath}`}
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                        key={feedPath}
                      />
                    ))}
                    <Route path={`${NAV_PATH.PAGES}`}>
                      <Route
                        index
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path="new"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path=":id/edit"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    </Route>
                    <Route path={`${NAV_PATH.POLLS}`}>
                      <Route
                        index
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path="new"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path=":id/edit"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    </Route>
                    <Route
                      path={`${NAV_PATH.TRANSLATIONS}`}
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    >
                      <Route
                        path=":languageId"
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    </Route>
                    <Route
                      path={`${NAV_PATH.SETTINGS}`}
                      element={<SystemSettingsPage />}
                    />
                    <Route
                      path={`${NAV_PATH.USERS}`}
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />

                    <Route path={`${NAV_PATH.CHANGES}`}>
                      <Route
                        index
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path={`${NAV_PATH.COMPANY}/:id`}
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path={`${NAV_PATH.DEPARTMENT}/:id`}
                        element={<ChangesCompanyDepartmentPage />}
                      />
                      <Route
                        path={`${NAV_PATH.SERVICE}/:id`}
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                      <Route
                        path={`${NAV_PATH.DOCTOR}/:id`}
                        element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                      />
                    </Route>
                    <Route
                      path={`${NAV_PATH.MESSAGES}`}
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                    <Route
                      path={`${NAV_PATH.AGREEMENT}`}
                      element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
                    />
                  </Route>
                )}
              </Route>
              {[NAV_PATH.VERIFICATION, NAV_PATH.VERIFICATION_EN].map(
                (verificationPath) => (
                  <Route
                    path={`/${verificationPath}`}
                    element={<VerificationPage />}
                    key={verificationPath}
                  />
                )
              )}
              <Route
                path="*"
                element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
              />
            </Routes>
            <QuickHelpButton />
          </Suspense>
        </>
      );
    }
    return (
      <>
        <AppMeta />
        <Routes>
          <Route index element={<HomePage />} />
          <Route element={<MenuLayout />}>
            {[NAV_PATH.DASHBOARD, NAV_PATH.DASHBOARD_EN].map(
              (dashboardPath) => (
                <Route
                  path={`/${dashboardPath}`}
                  element={<DashboardPage />}
                  key={dashboardPath}
                />
              )
            )}
            <Route
              path={`${NAV_PATH.PROFILE}/:authID`}
              element={<ProfilePage />}
            />
            <Route path={`${NAV_PATH.PROFILE}`} element={<ProfilePage />} />
            <Route
              path={`${NAV_PATH.SETTINGS}`}
              element={<AccountSettingsPage />}
            />
            <Route
              path={`${NAV_PATH.ARTICLES}`}
              element={
                isAuthPermission("articles", authUser) ? (
                  <ArticlesPage />
                ) : (
                  <NoAccessPage />
                )
              }
            />
            {[NAV_PATH.FEED, NAV_PATH.FEED_EN].map((feedPath) => (
              <Route
                path={`${feedPath}`}
                element={
                  isAuthPermission("feed", authUser) ? (
                    <FeedPage />
                  ) : (
                    <NoAccessPage />
                  )
                }
                key={feedPath}
              >
                <Route path=":feedId" element={<FeedPage />} />
                {feedPath === NAV_PATH.FEED && (
                  <Route
                    path={`:feedId/${NAV_PATH.COMMENT}/:commentId`}
                    element={<FeedPage />}
                  />
                )}
                {feedPath === NAV_PATH.FEED_EN && (
                  <Route
                    path={`:feedId/${NAV_PATH.COMMENT_EN}/:commentId`}
                    element={<FeedPage />}
                  />
                )}
              </Route>
            ))}
            <Route
              path={`${NAV_PATH.EVENTS}`}
              element={
                isAuthPermission("events", authUser) ? (
                  <EventsPage />
                ) : (
                  <NoAccessPage />
                )
              }
            />
            <Route path={`${NAV_PATH.EVENTS}/:id`} element={<EventPage />} />
            {isDoctor && (
              <Route path={`${NAV_PATH.DOCTOR}`}>
                <Route path=":me" element={<DoctorPage />} />
              </Route>
            )}
            {[NAV_PATH.LOYALTY_PROGRAM, NAV_PATH.LOYALTY_PROGRAM_EN].map(
              (loyaltyProgramPath) => (
                <Route path={`${loyaltyProgramPath}`} key={loyaltyProgramPath}>
                  <Route index element={<LoyaltyProgramPage />} />
                  {loyaltyProgramPath === NAV_PATH.LOYALTY_PROGRAM && (
                    <>
                      <Route
                        path={`${NAV_PATH.SERVICE}`}
                        element={<LoyaltyProgramServicePage />}
                      />
                      <Route
                        path={`${NAV_PATH.SERVICE}/:id`}
                        element={<LoyaltyProgramServiceContent />}
                      />
                      <Route
                        path={`${NAV_PATH.COMPANY}/:id`}
                        element={<LoyaltyProgramServiceCompanyContent />}
                      />
                    </>
                  )}
                  {loyaltyProgramPath === NAV_PATH.LOYALTY_PROGRAM_EN && (
                    <>
                      <Route
                        path={`${NAV_PATH.SERVICE_EN}`}
                        element={<LoyaltyProgramServicePage />}
                      />
                      <Route
                        path={`${NAV_PATH.SERVICE_EN}/:id`}
                        element={<LoyaltyProgramServiceContent />}
                      />
                      <Route
                        path={`${NAV_PATH.COMPANY_EN}/:id`}
                        element={<LoyaltyProgramServiceCompanyContent />}
                      />
                    </>
                  )}
                </Route>
              )
            )}
            {isSponsor && (
              <Route path={`${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}`}>
                <Route
                  path={`${NAV_PATH.COMPANY}/:id`}
                  element={<LoyaltyProgramSponsorPage />}
                />
              </Route>
            )}
            <Route path={`${NAV_PATH.HELP}`} element={<HelpPage />} />
            {/*zamiana Messengera na inny system*/}
            {/*https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76*/}
            {/*{isCallCenter && (*/}
            {/*  <Route path={`${NAV_PATH.ADMIN}`}>*/}
            {/*    <Route*/}
            {/*      path={`${NAV_PATH.MESSENGER}`}*/}
            {/*      element={<AdminMessengerPage />}*/}
            {/*    />*/}
            {/*  </Route>*/}
            {/*)}*/}
            {isAdmin && (
              <Route path={`${NAV_PATH.ADMIN}`}>
                <Route index element={<AdminPage />} />
                {/*zamiana Messengera na inny system*/}
                {/*https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76*/}
                {/*<Route*/}
                {/*  path={`${NAV_PATH.*/}
                {/*    MESSENGER}`}*/}
                {/*  element={<AdminMessengerPage />}*/}
                {/*/>*/}
                <Route path={`${NAV_PATH.ARTICLES}`}>
                  <Route index element={<ArticlesAdminPage />} />
                  <Route path="new" element={<ArticlesNewPage />} />
                  <Route path=":id/edit" element={<ArticlesEditPage />} />
                </Route>
                <Route path={`${NAV_PATH.COMPANIES}`}>
                  <Route index element={<CompaniesAdminPage />} />
                  <Route
                    path={`:id/${NAV_PATH.COMPANY}/edit`}
                    element={<CompanyEditPage />}
                  />
                  <Route
                    path={`:id/${NAV_PATH.SERVICE}/edit`}
                    element={<ServiceEditPage />}
                  />
                </Route>
                <Route
                  path={`${NAV_PATH.CATEGORIES}`}
                  element={<CategoriesPage />}
                />
                <Route path={`${NAV_PATH.MEDIA}`} element={<MediaPage />} />
                <Route path={`${NAV_PATH.CANCERS}`}>
                  <Route index element={<CancersPage />} />
                  <Route path="new" element={<CancerNewPage />} />
                  <Route path=":id/edit" element={<CancersEditPage />} />
                </Route>
                {[NAV_PATH.FEED, NAV_PATH.FEED_EN].map((feedPath) => (
                  <Route path={`${feedPath}`} element={<FeedCategoryPage />} />
                ))}
                <Route path={`${NAV_PATH.PAGES}`}>
                  <Route index element={<PagesPage />} />
                  <Route path="new" element={<PageNewPage />} />
                  <Route path=":id/edit" element={<PageEditPage />} />
                </Route>
                <Route path={`${NAV_PATH.POLLS}`}>
                  <Route index element={<PollsPage />} />
                  <Route path="new" element={<PollNewPage />} />
                  <Route path=":id/edit" element={<PollEditPage />} />
                </Route>
                <Route
                  path={`${NAV_PATH.TRANSLATIONS}`}
                  element={<TranslationsPage />}
                >
                  <Route
                    path=":languageId"
                    element={<TranslationsLanguagePage />}
                  />
                </Route>
                <Route
                  path={`${NAV_PATH.SETTINGS}`}
                  element={<SystemSettingsPage />}
                />
                <Route path={`${NAV_PATH.USERS}`} element={<UsersPage />} />

                <Route path={`${NAV_PATH.CHANGES}`}>
                  <Route index element={<ChangesPage />} />
                  <Route
                    path={`${NAV_PATH.COMPANY}/:id`}
                    element={<ChangesCompanyPage />}
                  />
                  <Route
                    path={`${NAV_PATH.DEPARTMENT}/:id`}
                    element={<ChangesCompanyDepartmentPage />}
                  />
                  <Route
                    path={`${NAV_PATH.SERVICE}/:id`}
                    element={<ChangesLoyaltyProgramServicePage />}
                  />
                  <Route
                    path={`${NAV_PATH.DOCTOR}/:id`}
                    element={<ChangesDoctorPage />}
                  />
                </Route>
                <Route
                  path={`${NAV_PATH.MESSAGES}`}
                  element={<MessagesPage />}
                />
                <Route
                  path={`${NAV_PATH.AGREEMENT}`}
                  element={<AgreementPage />}
                />
                <Route
                  path={`${NAV_PATH.CARD_CODES}`}
                  element={<CardCodesPage />}
                />
              </Route>
            )}
          </Route>

          {[NAV_PATH.VERIFICATION, NAV_PATH.VERIFICATION_EN].map(
            (verificationPath) => (
              <Route
                path={`/${verificationPath}`}
                element={<VerificationPage />}
                key={verificationPath}
              />
            )
          )}
          <Route
            path="*"
            element={<Navigate to={`/${NAV_PATH.DASHBOARD}`} />}
          />
        </Routes>
        <QuickHelpButton />
      </>
    );
  } else {
    return (
      <>
        <AppMeta />
        <Routes>
          <Route index element={<HomePage />} />
          {[NAV_PATH.VERIFICATION, NAV_PATH.VERIFICATION_EN].map(
            (verificationPath) => (
              <Route
                path={`/${verificationPath}`}
                element={<VerificationPage />}
                key={verificationPath}
              />
            )
          )}
          <Route path="*" element={<HomePage />} />
        </Routes>
        <QuickHelpButton isUserLoggedIn={false} />
      </>
    );
  }
}
export default App;

