// React imports
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// Redux services
import { useGetPollQuery } from "redux/services/pollsApi";
import { usePostPollQuestionAnswerMutation } from "redux/services/pollAnswerApi";
import {
  useCreatePollUserMutation,
  useCheckVerificationCodeMutation,
} from "redux/services/pollUsersApi";
import { usePostAttachmentsMutation } from "redux/services/attachmentsApi";
import {
  useGetSFContactQuery,
  usePatchSFContactMutation,
} from "redux/services/salesforceApi";
import { usePostDoctorReviewMutation } from "redux/services/doctorApi";
import { usePostFacilityReviewsMutation } from "redux/services/facilityReviewsApi";
import { useGetMeQuery, usePatchMeMutation } from "redux/services/meApi";
import { useDispatch } from "react-redux";
import { setPollId } from "redux/Slice/event-poll-slice";

// Absolute imports from the project
import { RootState } from "redux/store/index";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import PollPagination from "components/molecules/PollPagination/PollPagination";
import PollsError from "components/atoms/PollsError/PollsError";
import { convertAllValuesToString } from "Helpers/conversionHelpers";

// Relative imports
import RenderPollQuestion from "../../molecules/RenderPollQuestion/RenderPollQuestion";
import PollVerificationInput from "components/atoms/PollVerficationInput/PollVerficationInput";
import LoadingScreen from "components/atoms/LoadingScreen/LoadingScreen";

interface DynamicPatientPollProps {
  pollId?: number | null;
  setIsPollSend?: () => void;
  defaultAnswers?: { [key: string]: string };
  readOnly?: boolean;
  doctorIdProp?: number;
  facilityIdProp?: number;
  isEvent?: boolean;
}

interface PollDefaultValueLoadingState {
  isDatalistInputLoading: boolean;
  isSelectInputLoading: boolean;
  isAttachmentsInputLoading: boolean;
  isFacilitiesSelectLoading: boolean;
}

export type SetPollDefaultValueLoadingState = React.Dispatch<
  React.SetStateAction<PollDefaultValueLoadingState>
>;

const DynamicPatientPoll: React.FC<DynamicPatientPollProps> = ({
  pollId,
  setIsPollSend,
  defaultAnswers,
  readOnly,
  doctorIdProp,
  facilityIdProp,
  isEvent,
}) => {
  const { t } = useTranslation();
  const selectData = useSelector((state: RootState) => state.select);
  const { authUser } = useSelector((state: RootState) => state.user);
  const doctorId = selectData.doctorId as number;
  const facilityId = selectData.facilityId as number;
  const {
    data: sfAnswers,
    refetch: refetchAnswers,
    isFetching: sfAnswersLoading,
  } = useGetSFContactQuery({});
  const { data: pollData, isLoading: pollDataLoading } =
    useGetPollQuery(pollId);
  const { data: userMeData, isLoading: userDataLoading } = useGetMeQuery({});
  const [postPollQuestionAnswer, { isLoading }] =
    usePostPollQuestionAnswerMutation();
  const [postAttachment] = usePostAttachmentsMutation();
  const [createPollUser] = useCreatePollUserMutation();
  const [postDoctorReview] = usePostDoctorReviewMutation();
  const [postFacilityReview] = usePostFacilityReviewsMutation();
  const [patchSfData] = usePatchSFContactMutation();
  const [patchUserSettings] = usePatchMeMutation();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [data, setData] = useState<PatientPollData | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nextPageClicked, setNextPageClicked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [modalContent, setModalContent] = useState<{
    loading: boolean;
    message?: string;
    error?: string;
    status?: string;
  }>({ loading: false });
  const [verifiedPollUserId, setVerifiedPollUserId] = useState<number>();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [checkVerificationCode] = useCheckVerificationCodeMutation();

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const dispatch = useDispatch();
  const currentUserData = userMeData?.data;
  const rolesToCheck = ["doctor", "patient", "sponsor", "guardian"];
  const userRole = userMeData?.data?.roles?.find((roleObject: Roles) =>
    rolesToCheck.includes(roleObject?.role?.role)
  );
  const pagesLength = data?.pages?.length ?? 0;

  const methods = useForm({
    defaultValues: defaultAnswers || {},
  });

  const [pollDefaultValueLoadingState, setPollDefaultValueLoadingState] =
    useState<PollDefaultValueLoadingState>({
      isDatalistInputLoading: true,
      isSelectInputLoading: true,
      isAttachmentsInputLoading: true,
      isFacilitiesSelectLoading: true,
    });
  const [pollLoading, setPollLoading] = useState<boolean>(false);

  useEffect(() => {
    const anyLoading =
      sfAnswersLoading ||
      pollDataLoading ||
      userDataLoading ||
      pollDefaultValueLoadingState.isSelectInputLoading ||
      pollDefaultValueLoadingState.isAttachmentsInputLoading ||
      pollDefaultValueLoadingState.isDatalistInputLoading ||
      pollDefaultValueLoadingState.isFacilitiesSelectLoading;

    setPollLoading(anyLoading);
  }, [
    sfAnswersLoading,
    pollDataLoading,
    userDataLoading,
    pollDefaultValueLoadingState,
  ]);

  useEffect(() => {
    const datalist = data?.pages?.[currentPage]?.questions?.find(
      (question) => question.fieldType === "datalist"
    );

    const select = data?.pages?.[currentPage]?.questions?.find(
      (question) => question.fieldType === "select"
    );

    const attachments = data?.pages?.[currentPage]?.questions?.find(
      (question) => question.fieldType === "attachments"
    );

    const facilitiesSelect = data?.pages?.[currentPage]?.questions?.find(
      (question) => question.fieldType === "facilities-select"
    );

    if (data) {
      if (!datalist) {
        setPollDefaultValueLoadingState((prevState) => ({
          ...prevState,
          isDatalistInputLoading: false,
        }));
      }

      if (!select) {
        setPollDefaultValueLoadingState((prevState) => ({
          ...prevState,
          isSelectInputLoading: false,
        }));
      }

      if (!attachments) {
        setPollDefaultValueLoadingState((prevState) => ({
          ...prevState,
          isAttachmentsInputLoading: false,
        }));
      }

      if (!facilitiesSelect) {
        setPollDefaultValueLoadingState((prevState) => ({
          ...prevState,
          isFacilitiesSelectLoading: false,
        }));
      }
    }
  }, [data, currentPage]);

  useEffect(() => {
    refetchAnswers();
    if (pollData) {
      const filteredPages = pollData?.data?.pages?.filter(
        (page: PatientPollPage) => page.isActive !== 0
      );
      setData({ ...pollData.data, pages: filteredPages });
    }
    if (pollData?.data?.verificationType === "none") {
      setIsVerified(true);
    }
    // eslint-disable-next-line
  }, [pollData]);

  const nextPage = async () => {
    const isValid = await methods.trigger();

    setNextPageClicked(true);

    if (isValid && currentPage < (data?.pages.length || 0) - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
      setNextPageClicked(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  if (isNaN(Number(pollId))) {
    return <PollsError />;
  }

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmAction = () => {
    closeModal();
  };

  const getIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      toast.error(`${t("toast_dynamicPatientPoll_ip_error")} ${error}`);
      return null;
    }
  };

  const uploadFileData = async (file: FileData, attachmentIds: string[]) => {
    const uploadResult = await postAttachment({
      data: file,
      attachmentType: "polls",
      fileName: file.filename,
    }).unwrap();
    if (uploadResult?.data?.id) {
      attachmentIds.push(uploadResult.data.id);
    }
  };

  const createPollUserRequest = async (
    pollUser: PollUserRequest
  ): Promise<number | void> => {
    const resultPollUser = await createPollUser(pollUser);

    if ("error" in resultPollUser) {
      toast.error(
        `${t("toast_dynamicPatientPoll_create_poll_user_error")} ${
          resultPollUser.error
        }`
      );
      return;
    }
    if (isEvent) {
      dispatch(setPollId(resultPollUser?.data?.data?.id));
    }
    if (resultPollUser?.data?.statusCode === 400) {
      toast.error(
        `${t("toast_dynamicPatientPoll_create_poll_user_error_400")} ${
          resultPollUser.data.message
        }`
      );
      return;
    }

    if (resultPollUser?.data?.data) {
      return resultPollUser.data.data.id;
    } else if (resultPollUser?.data?.data?.statusCode === 404) {
      toast.error(t("toast_dynamicPatientPoll_create_poll_user_error_404"));
    } else if (resultPollUser?.data?.data?.statusCode === 500) {
      toast.error(t("toast_dynamicPatientPoll_create_poll_user_error_500"));
    } else {
      toast.error(
        `${t("toast_dynamicPatientPoll_create_poll_user_unexpected_error")} ${
          resultPollUser?.data?.data?.message
        }`
      );
    }
  };

  const postAnswers = async (questionsWithAnswers: QuestionWithAnswer[]) => {
    const postPromises = questionsWithAnswers.map((answer) => {
      const jsonAnswer = JSON.stringify(answer);
      return postPollQuestionAnswer({ data: jsonAnswer });
    });

    const results = await Promise.allSettled(postPromises);

    const rejectedAnswers = results.filter(
      (result) => result.status === "rejected"
    );
    if (rejectedAnswers?.length > 0) {
      toast.error(t("toast_dynamicPatientPoll_poll_answer_error"));
    }
  };

  const convertToSfFormat = (questionWithAnswer: QuestionWithAnswer) => {
    const { fieldname, answer } = questionWithAnswer;

    if (answer !== null) {
      if (typeof answer === "string" && !isNaN(Number(answer))) {
        return { [fieldname]: Number(answer) };
      }
      if (typeof answer === "string" && answer === "true") {
        return { [fieldname]: true };
      }
      if (typeof answer === "string" && answer === "false") {
        return { [fieldname]: false };
      }
      return { [fieldname]: answer };
    }
    return {};
  };

  const postAnswersToSf = async (questionsWithAnswers: QuestionWithAnswer[]) => {
    const sfFormattedAnswers: { [key: string]: any } = questionsWithAnswers.reduce(
      (accumulatedAnswers, currentQuestion) => {
        return {
          ...accumulatedAnswers,
          ...convertToSfFormat(currentQuestion),
        };
      },
      {}
    );

    if (data?.sfConnection === 1) {
      sfFormattedAnswers.pollID__c = pollId;
    }
  
    const { settings, localSettings } = currentUserData;
  
    const patchData = sfFormattedAnswers;

    const pollIdKey = `${userRole.name}_poll_Id`;
  
    const updatedLocalSettings = {
      ...localSettings,
      [pollIdKey]: pollId,
    };
  
    const patchSettingsData = {
      settings: { ...settings, ...sfFormattedAnswers },
      localSettings: updatedLocalSettings,
    };
  
    try {
      const res = await patchSfData({ data: patchData });
      await patchUserSettings({ data: patchSettingsData });
      if ("data" in res && (res.data.error || res.data.statusCode >= 400)) {
        toast.error(res.data.message || "Wystąpił nieznany błąd");
      }
    } catch (error) {
      toast.error(t("toast_dynamicPatientPoll_poll_answer_error"));
    }
  };

  const checkVerification = async () => {
    if (pollData?.data?.verificationType === "none") {
      setIsVerified(true);
      return;
    }

    try {
      const checkResult = await checkVerificationCode({
        id: verifiedPollUserId,
        data: { verificationHash: verificationCode },
      }).unwrap();

      if (checkResult?.error || checkResult?.statusCode === 400) {
        toast.error(`Błąd: podany kod jest niepoprawny`);
      } else if (checkResult?.data?.isActive === 1) {
        setIsVerified(true);
      } else {
        toast.error("Weryfikacja nieudana lub status nieaktywny.");
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Nieznany błąd weryfikacji.";
      toast.error(`Błąd weryfikacji: ${errorMessage}`);
    }
  };

  const handleFormSubmit = async () => {
    const isFormValid = await methods.trigger();
    if (!isFormValid) return;
  
    const verificationType = pollData?.data?.verificationType;
  
    setIsSubmitting(true);
    setModalContent({ loading: true, status: "sending" });
    openModal();
  
    try {
      const ipAddress = await getIpAddress();
      const rawFormData = methods.getValues();
      const attachmentIds: { [key: string]: string[] } = {};
      const formDataWithFilesProcessed = { ...rawFormData };
  
      for (const key in rawFormData) {
        const value = rawFormData[key];
        if (Array.isArray(value)) {
          if (value.every((item: any) => typeof item === "string")) {
            formDataWithFilesProcessed[key] = value.join(",");
          } else {
            const files = value as FileData[];
            attachmentIds[key] = [];
            for (const file of files) {
              await uploadFileData(file, attachmentIds[key]);
            }
            if (attachmentIds[key].length > 0) {
              formDataWithFilesProcessed[key] = attachmentIds[key].join(",");
            }
          }
        }
      }
      const formDataAsString = convertAllValuesToString(formDataWithFilesProcessed);
      for (const key in formDataAsString) {
        if (
          typeof formDataAsString[key] === "string" &&
          (formDataAsString[key].includes("[object File]") ||
            formDataAsString[key].includes("[object Files]"))
        ) {
          console.error(`Pole "${key}" zawiera [object File/Files]. Przerywam wysyłanie.`);
          toast.error("toast_dynamicPatientPoll_poll_answer_error");
          setModalContent({
            loading: false,
            error: "Wysyłanie ankiety przerwane - wykryto nieprzetworzony plik.",
            status: "error",
          });
          setIsSubmitting(false);
          return;
        }
      }
  
      let pollUserId: number | void;
      if (verificationType === "none") {
        if (ipAddress) {
          const pollUser: PollUserRequest = {
            pollId: pollId || null,
            userId: authUser?.id || null,
            ip: ipAddress,
          };
          pollUserId = await createPollUserRequest(pollUser);
        }
      } else {
        pollUserId = verifiedPollUserId;
      }
  
      const questionsWithAnswers =
        data?.pages?.flatMap((page: PatientPollPage) =>
          page?.questions?.map((question: PatientPollQuestion) => ({
            pollUserId,
            pollPageQuestionId: question.id,
            answer: formDataAsString[question.fieldName as string] || null,
            sfSynchro: question.sfSynchro,
            fieldname: question.fieldName,
            fieldType: question.fieldType,
            meta: question?.meta,
          }))
        ) || [];
  
      await postAnswers(questionsWithAnswers);
  
      const doctorReviews = questionsWithAnswers.filter((q) =>
        q?.meta?.some(
          (metaItem) =>
            metaItem.key === "output_table_name" &&
            metaItem.value === "doctor_reviews"
        )
      );
  
      if (doctorReviews.length > 0) {
        const doctorRatingValues: number[] = doctorReviews
          .filter((review) => review.fieldType === "rating-radio")
          .map((review) => Number(review.answer));
  
        const doctorAverageRating =
          doctorRatingValues.reduce((acc, val) => acc + val, 0) /
          doctorRatingValues.length;
  
        const combinedContent = doctorReviews
          .filter(
            (review) =>
              review.fieldType === "text" || review.fieldType === "textarea"
          )
          .map((review) => review.answer)
          .join(" ");
  
        const effectiveDoctorId = doctorIdProp || doctorId;
        const payload = {
          data: {
            content: combinedContent,
            rating: doctorAverageRating,
            doctorId: Number(effectiveDoctorId),
            deleted: 0,
            status: "AWAITING_REVIEW",
            systemId: 1,
          },
        };
  
        try {
          await postDoctorReview(payload);
        } catch (error) {
          console.error("Error while posting doctor review:", error);
        }
      }
  
      const facilityReviews = questionsWithAnswers?.filter((q) =>
        q?.meta?.some(
          (metaItem) =>
            metaItem.key === "output_table_name" &&
            metaItem.value === "facility_reviews"
        )
      );
  
      if (facilityReviews && facilityReviews.length > 0) {
        const facilityRatingValues: number[] = facilityReviews
          .filter((review) => review.fieldType === "rating-radio")
          .map((review) => Number(review.answer));
  
        const facilityAverageRating =
          facilityRatingValues?.reduce((acc, val) => acc + val, 0) /
            facilityRatingValues?.length || 0;
  
        const combinedFacilityContent = facilityReviews
          .filter(
            (review) =>
              review.fieldType === "text" || review.fieldType === "textarea"
          )
          .map((review) => review.answer)
          .join(" ");
  
        const effectiveFacilityId = facilityIdProp || facilityId;
        const name = userMeData?.data?.name || "Anonimowy użytkownik";
  
        const facilityPayload = {
          facility: Number(effectiveFacilityId),
          content: combinedFacilityContent,
          rating: facilityAverageRating,
          name: name,
          source: 1,
        };
  
        try {
          await postFacilityReview(facilityPayload);
        } catch (error) {
          console.error("Error while posting facility review:", error);
        }
      }
  
      const questionsWithAnswersSfSynchro = questionsWithAnswers?.filter(
        (question) => question.sfSynchro === 1
      );
      await postAnswersToSf(questionsWithAnswersSfSynchro);
  
      if (!isLoading) {
        setModalContent({
          loading: false,
          message: "Dziękujemy za twoją opinię.",
          status: "sent",
        });
        setIsPollSend && setIsPollSend();
        setIsFormSubmitted(true);
        refetchAnswers();
      }
    } catch (error) {
      setModalContent({
        loading: false,
        error: `Nie udało się wysłać ankiety: ${error}`,
        status: "error",
      });
    }
    setIsSubmitting(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const displayPopupStatusText = (status?: string | undefined) => {
    switch (status) {
      case "sending":
        return "Trwa wysyłanie danych. Proszę czekać.";
      case "sent":
        return "Dziękujemy za twoją opinię.";
      default:
        return modalContent?.error ?? "Błąd wysyłania";
    }
  };

  const renderQuestion = (question: PatientPollQuestion) => {
    let defaultAnswer;
    console.log(readOnly)
    readOnly
      ? (defaultAnswer = defaultAnswers?.[question.fieldName])
      : (defaultAnswer =
          defaultAnswers?.[question.fieldName] ||
          userMeData?.settings?.[question?.fieldName] ||
          sfAnswers?.data?.[question?.fieldName]);

    if (sfAnswersLoading || pollDataLoading || userDataLoading) {
      return null;
    }

    return (
      <RenderPollQuestion
        key={question.id}
        question={question}
        nextPageClicked={nextPageClicked}
        readOnly={readOnly}
        defaultAnswer={defaultAnswer}
        setPollDefaultValueLoadingState={setPollDefaultValueLoadingState}
      />
    );
  };

  const renderQuestions = (questions: PatientPollQuestion[]) => {
    return questions.map((question) => renderQuestion(question));
  };

  const display = pollLoading || isSubmitting ? "none" : "block";

  return (
    <>
      {pollLoading && !isSubmitting && <LoadingScreen isPoll={true} />}

      <div style={{ display: display }}>
        {isFormSubmitted ? null : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleFormSubmit)}
              className={`row align-items-stretch mt-5 text-dark-blue ${
                readOnly ? "" : "p-4 m-0 pt-4 containerWithShadow border"
              }`}
            >
              {currentPage === 0 && data && (
                <>
                  <h2 className={`fs-22 fw-600 mb-5 mt-1 text-dark-blue ls-3`}>
                    {data.title}
                  </h2>
                  {data.description && (
                    <p className="mt-10 mb-4 fs-16 ">{data.description}</p>
                  )}
                </>
              )}
              {data && (
                <>
                  {readOnly ? (
                    data.pages.flatMap((page) =>
                      page?.isActive === 1
                        ? renderQuestions(page?.questions)
                        : []
                    )
                  ) : (
                    <>
                      <h6 className={`mt-12 mb-12 fw-600 fs-12 text-grey-2`}>
                        {data?.pages?.[currentPage]?.title}
                      </h6>
                      <p className="mt-12 mb-12 fw-600 fs-12">
                        {data?.pages?.[currentPage]?.description}
                      </p>
                      {data?.pages?.[currentPage]?.isActive === 1 &&
                        renderQuestions(data?.pages?.[currentPage]?.questions)}
                    </>
                  )}
                  {currentPage === pagesLength - 1 &&
                    (pollData?.data?.verificationType === "sms" ||
                      pollData?.data?.verificationType === "email") && (
                      <PollVerificationInput
                        type={pollData?.data?.verificationType}
                        setVerifiedPollUserId={setVerifiedPollUserId}
                        data={pollData?.data}
                        setVerificationCode={setVerificationCode}
                      />
                    )}

                  {!readOnly && (
                    <PollPagination
                      currentPage={currentPage}
                      totalSteps={pagesLength}
                      prevPage={prevPage}
                      nextPage={nextPage}
                      isLastPage={currentPage === pagesLength - 1}
                      handleFormSubmit={handleFormSubmit}
                      isSubmitting={isSubmitting}
                      verificationType={pollData?.data?.verificationType}
                      isVerified={isVerified}
                      checkVerification={checkVerification}
                    />
                  )}
                </>
              )}
            </form>
          </FormProvider>
        )}
        <PopupModal
          show={showModal}
          setShow={setShowModal}
          text={displayPopupStatusText(modalContent?.status)}
          loading={modalContent.loading}
          type="info"
          confirmAction={confirmAction}
          titleClasses="fw-600 text-center"
          smallText={
            modalContent.status !== "sent"
              ? ""
              : "Jak tylko pojawi się ona na stronie poinformujemy Cie o tym."
          }
          hideHeader={true}
        />
      </div>
    </>
  );
};

export default DynamicPatientPoll;
