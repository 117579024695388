import { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useGetPollQuestionAnswersQuery } from "redux/services/pollAnswerApi";
import DynamicPatientPoll from "../DynamicPatientPoll/DynamicPatientPoll";
import { usePatchUserSFContactMutation } from "redux/services/usersApi";
import { toast } from "react-toastify";

interface PollAnswerModalProps {
  show: boolean;
  onHide: () => void;
  user: PollDisplayData | null;
  readOnly?: boolean;
}

const PollAnswerModal: React.FC<PollAnswerModalProps> = ({
  show,
  onHide,
  user,
  readOnly = false,
}) => {
  const pollUserID = user?.id;
  const userId = user?.user.id; 
  const { data: answersData, isLoading } = useGetPollQuestionAnswersQuery({
    pollUser: `${pollUserID}`,
  });
  const [patchSFcontact] = usePatchUserSFContactMutation();
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    if (answersData?.pollQuestionAnswers) {
      setAnswers(answersData.pollQuestionAnswers);
    }
  }, [answersData]);

  const convertDefaultAnswers = (
    answers: Answer[],
  ): { [key: string]: string } => {
    const defaultValues: { [key: string]: string } = {};
    if (!!answers) {
      answers?.forEach((item) => {
        if (!item?.pollPageQuestion?.fieldName) {
          return;
        }
        defaultValues[item?.pollPageQuestion?.fieldName] = item.answer;
      });
    }
    return defaultValues;
  };
  const defaultValues = answers ? convertDefaultAnswers(answers) : {};
  
  const handleSyncToSalesforce = async () => {
    if(!userId){
      toast.error('Nie udało się znalezc id uzytkownika');
      return
    }
    setIsSyncing(true);
    try {
      const response = await patchSFcontact({
        data: defaultValues,
        userId: userId.toString(),
      }).unwrap();

      if (response?.error || response?.statusCode >= 400) {
        toast.error(
          response?.message || "Wystąpił błąd podczas synchronizacji z Salesforce."
        );
      } else {
        toast.success("Dane zostały zsynchronizowane z Salesforce!");
      }
    } catch (error) {
      console.error("Błąd synchronizacji:", error);
      toast.error("Nie udało się zsynchronizować danych z Salesforce.");
    }
    finally {
      setIsSyncing(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Wynik ankiety</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" /> Wczytuje...
          </div>
        ) : answers.length > 0 ? (
          <>
            <DynamicPatientPoll
              pollId={user?.poll?.id}
              defaultAnswers={defaultValues}
              readOnly={true}
              setIsPollSend={onHide} />
              {readOnly &&
                <Button className="mt-2" onClick={handleSyncToSalesforce}>
                  {isSyncing ? "Synchronizuję..." : "Synchronizuj dane do Salesforce"}
                </Button>
              }
          </>
        ) : (
          <div className="text-center text-danger">
            Coś poszło nie tak, ta ankieta nie została poprawnie wypełniona
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PollAnswerModal;
