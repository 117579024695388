import SortButton from "components/organisms/SortButton/SortButton";
import PollAnswerModal from "components/organisms/PollAnswerModal/PollAnswerModal";
import { Button } from "react-bootstrap";
import { useState } from "react";
import styles from "./PollAnswerTable.module.scss";

interface PollAnswersTableProps {
  pollUsers: PollDisplayData[];
  sortField: string | null;
  sortOrder: "ASC" | "DESC";
  setSortField: React.Dispatch<React.SetStateAction<string | null>>;
  setSortOrder: React.Dispatch<React.SetStateAction<"ASC" | "DESC">>;
}

const PollAnswersTable: React.FC<PollAnswersTableProps> = ({
  pollUsers,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<PollDisplayData | null>(
    null
  );

  const handleSort = (field: string) => {
    let newField:
      | keyof PollDisplayData
      | "poll.id"
      | "poll.title"
      | "user.name"
      | "user.email"
      | "user.id";
    switch (field) {
      case "title":
        newField = "poll.title";
        break;
      default:
        newField = field as keyof PollDisplayData;
    }

    if (sortField === newField) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(newField);
      setSortOrder("ASC");
    }
  };

  const sortedUsers = [...pollUsers].sort((a, b) => {
    if (!sortField) return 0;

    let compareA: string | number, compareB: string | number;
    switch (sortField) {
      case "id":
        compareA = a.id;
        compareB = b.id;
        break;
      case "poll.id":
        compareA = a.poll?.id || "";
        compareB = b.poll?.id || "";
        break;
      case "poll.title":
        compareA = a.poll?.title || "";
        compareB = b.poll?.title || "";
        break;
      case "user.name":
        compareA = a.user?.name || "";
        compareB = b.user?.name || "";
        break;
      case "user.email":
        compareA = a.user?.email || "";
        compareB = b.user?.email || "";
        break;
      case "user.id":
        compareA = a.user?.id || "";
        compareB = b.user?.id || "";
        break;
      case "ip":
        compareA = a?.ip || "";
        compareB = b?.ip || "";
        break;
      case "createdAt":
        compareA = a?.createdAt || "";
        compareB = b?.createdAt || "";
        break;
      default:
        return 0;
    }

    return sortOrder === "ASC"
      ? String(compareA).localeCompare(String(compareB))
      : String(compareB).localeCompare(String(compareA));
  });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes} ${day}.${month}.${year}`;
  };

  return (
    <div className="table-responsive">
      <table className={`${styles.table}`}>
        <thead className="bg-white">
          <tr>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="ID"
                onClick={() => handleSort("id")}
                isArrowRotated={sortField === "id" && sortOrder === "DESC"}
              />
            </th>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="Adres IP"
                onClick={() => handleSort("user.ip")}
                isArrowRotated={sortField === "user.ip" && sortOrder === "DESC"}
              />
            </th>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="ID Ankiety"
                onClick={() => handleSort("poll.id")}
                isArrowRotated={sortField === "poll.id" && sortOrder === "DESC"}
              />
            </th>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="Nazwa Ankiety"
                onClick={() => handleSort("poll.title")}
                isArrowRotated={
                  sortField === "poll.title" && sortOrder === "DESC"
                }
              />
            </th>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="Użytkownik"
                onClick={() => handleSort("user.name")}
                isArrowRotated={
                  sortField === "user.name" && sortOrder === "DESC"
                }
              />
            </th>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="E-mail Użytkownika"
                onClick={() => handleSort("user.email")}
                isArrowRotated={
                  sortField === "user.email" && sortOrder === "DESC"
                }
              />
            </th>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="ID Użytkownika"
                onClick={() => handleSort("user.id")}
                isArrowRotated={sortField === "user.id" && sortOrder === "DESC"}
              />
            </th>
            <th className={`p-2 ${styles.responsiveHeader}`}>
              <SortButton
                label="Data wypełnienia"
                onClick={() => handleSort("createdAt")}
                isArrowRotated={
                  sortField === "createdAt" && sortOrder === "DESC"
                }
                className={` ${styles.responsiveHeader}`}
              />
            </th>
            <th className="text-grey fs-14 fw-medium">Działania</th>
          </tr>
        </thead>
        <tbody className="fs-13">
          {sortedUsers.map((user: PollDisplayData) => (
            <tr key={user.id} className="border ">
              <td className="p-2">{user.id}</td>
              <td className="p-2">{user.ip}</td>
              <td className="p-2">{user.poll?.id}</td>
              <td className="p-2">{user.poll?.title}</td>
              <td className="p-2">{user.user?.name}</td>
              <td className="p-2">{user.user?.email}</td>
              <td className="p-2">{user.user?.id}</td>
              <td className="p-2">{formatDate(user.createdAt)}</td>
              <td className="p-2">
                <Button
                  onClick={() => {
                    setIsModalOpen(true);
                    setSelectedUser(user);
                  }}
                >
                  Wyświetl
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PollAnswerModal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        user={selectedUser}
        readOnly={isModalOpen}
      />
    </div>
  );
};

export default PollAnswersTable;
